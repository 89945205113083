<template>
    <div class="report-select-reason">
        <StackRouterHeaderBar :leftButton="'close'" />
        <div class="top">
            <h1 class="title m-b-12" v-html="title" />
        </div>

        <ul v-if="reasons.length > 0" class="reasons m-t-40">
            <li class="reason" v-for="reason in reasons" :key="reason.id" @click="onClickReason(reason)">
                <div class="content">
                    <span class="name">{{ reason.title }}</span>
                    <span v-if="reason.content" class="detail">{{ reason.content }}</span>
                </div>
                <i class="material-icons">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
// import userService from '@/services/user'

export default {
    name: 'ReportSelectReasonPage',
    props: {
        target: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    computed: {
        title() {
            return `${this.target.nickname}님을<br> 신고하는 이유를 선택해 주세요`
        },
        reasons() {
            return this.$store.getters.categories.filter(c => c.category === 'reports')
        },
    },
    methods: {
        async onClickReason(reason) {
            this.$stackRouter.push({
                name: 'ReportSubmitPage',
                props: {
                    reason,
                    target: this.target,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.report-select-reason {
    .top {
        margin-left: 16px;
    }
    .title {
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .desc {
        font-size: 14px;
        color: $grey-08;
    }

    .reasons {
        padding: 0 16px;

        .reason {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            background-color: $grey-01;
            margin-bottom: 12px;
            padding: 14px 16px;

            .material-icons {
                color: $grey-04;
            }
        }

        .content {
            @include center;

            .name {
                font-size: 16px;
                color: black;
                margin-right: 6px;
                @include f-medium;
            }

            .detail {
                font-size: 13px;
                color: $grey-07;
            }
        }
    }
}
</style>
